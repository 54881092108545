import { UserContext } from "@/contexts/user-context";
import { useCancelBookingMutation, useCreateBookingMutation } from "@/generated/client.generated";
import type { MyGroupActivityBookingTypes } from "@/utils/booking-utils";
import { parseGraphqlError } from "@/utils/graphql";
import { useContext, useEffect, useState } from "react";

export function useBooking(bookingId: string, bookingData?: MyGroupActivityBookingTypes) {
  const { refetchMyBookedActivities } = useContext(UserContext);
  const [createBooking, { loading: createBookingLoading }] = useCreateBookingMutation();
  const [cancelBooking, { loading: cancelBookingLoading }] = useCancelBookingMutation();

  const [booking, setBooking] = useState<MyGroupActivityBookingTypes | undefined>(bookingData);
  const [bookingError, setBookingError] = useState<string | undefined>(undefined);
  const [cancelError, setCancelError] = useState<string | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(false);

  const [confirmation, setConfirmation] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (createBookingLoading || cancelBookingLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createBookingLoading, cancelBookingLoading]);

  useEffect(() => {
    setBooking(bookingData);
  }, [bookingData]);

  const handleCancelBooking = async () => {
    setBookingError(undefined);
    setCancelError(undefined);
    setConfirmation(undefined);
    if (booking?.bookingType) {
      try {
        await cancelBooking({
          onCompleted: () => refetchMyBookedActivities(),
          variables: { bookingType: booking?.bookingType, id: booking?.id },
        });
        setCancelError(undefined);
        setBooking(undefined);
        setConfirmation("Timen er avbooket. Velkommen tilbake!");
      } catch (error) {
        setCancelError(parseGraphqlError(error));
      }
    }
  };

  const handleBooking = async () => {
    setCancelError(undefined);
    setBookingError(undefined);
    setConfirmation(undefined);

    try {
      const response = await createBooking({
        onCompleted: () => {
          refetchMyBookedActivities();
        },
        variables: { id: bookingId },
      });
      setBooking(response.data?.CreateBooking as MyGroupActivityBookingTypes);
      setBookingError(undefined);
    } catch (error) {
      setBookingError(parseGraphqlError(error));
    }
  };

  return { booking, bookingError, cancelError, confirmation, handleBooking, handleCancelBooking, loading };
}
