"use client";
import { type GAItem, addToCartEvent } from "@/analytics/event-logger";
import { UserContext } from "@/contexts/user-context";
import {
  type OrderFragment,
  type OrderItem,
  useCancelServiceBookingOrderMutation,
  useCreateServiceBookingOrderMutation,
  useGetServiceOrderGiftCardLazyQuery,
  useGetServiceOrderLazyQuery,
  useUpdateServiceBookingOrderMutation,
} from "@/generated/client.generated";
import { parseGraphqlError } from "@/utils/graphql";
import { ROOT_URL, paths } from "@/utils/paths-utils";
import { useSession } from "next-auth/react";
import { sum } from "rambda";
import { useContext, useEffect, useState } from "react";

interface UseServiceBookingProps {
  id?: string;
}
export const useServiceBooking = ({ id }: UseServiceBookingProps) => {
  const { refetchMyBookedActivities, refetchMyOrders } = useContext(UserContext);
  const session = useSession();

  const [createBooking, { loading: createBookingLoading }] = useCreateServiceBookingOrderMutation();
  const [cancelBooking, { loading: cancelBookingLoading }] = useCancelServiceBookingOrderMutation();
  const [updateBooking, { loading: updateBookingLoading }] = useUpdateServiceBookingOrderMutation();

  const [getOrder, { loading: getOrderLoading }] = useGetServiceOrderLazyQuery();
  const [getOrderGiftCard] = useGetServiceOrderGiftCardLazyQuery();

  useEffect(() => {
    async function getServiceOrder(id: string) {
      try {
        if (session.status === "loading") {
          return;
        }
        if (session.status === "authenticated") {
          await getOrder({
            variables: {
              id: id,
              redirectUrl: `${ROOT_URL}${paths.bookingReceipt(id)}`,
            },
          }).then((data) => {
            setBooking(data.data?.Order);
          });
        } else {
          await getOrderGiftCard({
            variables: {
              id: id,
              redirectUrl: `${ROOT_URL}${paths.bookingReceipt(id)}`,
            },
          }).then((data) => {
            setBooking(data.data?.OrderGiftCard);
          });
        }
      } catch (error) {
        setError(parseGraphqlError(error));
      }
    }
    if (id) {
      getServiceOrder(id);
    }
  }, [id, getOrder, session, getOrderGiftCard]);

  const [booking, setBooking] = useState<OrderFragment | undefined>(undefined);

  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (createBookingLoading || cancelBookingLoading || getOrderLoading || updateBookingLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [createBookingLoading, cancelBookingLoading, getOrderLoading, updateBookingLoading]);

  const handleUpdateOrder = async (id: string, orderItems: Array<OrderItem>) => {
    setError(undefined);
    setConfirmation(undefined);
    const redirectUrl = `${ROOT_URL}${paths.bookingReceipt(id)}`;

    try {
      const response = await updateBooking({
        onCompleted: () => {
          refetchMyBookedActivities();
          refetchMyOrders();
        },
        variables: {
          id: id,
          orderItems: orderItems,
          redirectUrl: redirectUrl,
        },
      });
      if (response.data?.UpdateOrder.paymentLink) {
        setBooking(response.data?.UpdateOrder);
      } else {
        setError("Kunne ikke oppdatere bestillingen. Prøv igjen senere");
      }
    } catch (error) {
      setError(parseGraphqlError(error));
    }
  };

  const handleCancelBooking = async (id: string) => {
    setError(undefined);
    setConfirmation(undefined);
    try {
      await cancelBooking({
        onCompleted: () => refetchMyBookedActivities(),
        variables: { id },
      });
      setBooking(undefined);
      setConfirmation("Timen er avbooket. Velkommen tilbake!");
    } catch (error) {
      setError(parseGraphqlError(error));
    }
  };

  const handleBooking = async (orderItems: Array<OrderItem>, centerId?: string) => {
    setError(undefined);
    setConfirmation(undefined);
    const redirectUrl = `${ROOT_URL}${paths.bookingReceipt(id ?? "")}`;

    try {
      const response = await createBooking({
        onCompleted: () => {
          refetchMyBookedActivities();
          refetchMyOrders();
          addToCartEvent(
            sum(orderItems.map((item) => item.amount?.amount ?? 0)) / 100,
            orderItems.map((item) => {
              return {
                item_brand: "3T",
                item_id: item.resourceId,
                item_name: item.productId,
                price: (item.amount?.amount ?? 0) / 100,
                quantity: 1,
              } as GAItem;
            }),
          );
        },
        variables: {
          centerId: centerId ?? "1", //Default Rosten,
          orderItems: orderItems,
          redirectUrl: redirectUrl,
        },
      });
      if (response.data?.CreateOrder.paymentLink) {
        setBooking(response.data?.CreateOrder);
      } else {
        setError("Could not create order");
      }
    } catch (error) {
      setError(parseGraphqlError(error));
    }
  };

  return {
    booking,
    confirmation,
    error,
    handleBooking,
    handleCancelBooking,
    handleUpdateOrder,
    loading,
  };
};
